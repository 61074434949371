import {
  useStyles,
  StepperComponent,
  useWindowSize,
  GlobalCard,
} from "@mfe/js-common-ave-uiutils";
import { LoadingOverlay } from "@mantine/core";
import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";

import {
  es,
  lodash,
  moment,
  parseDate,
  extractTime,
  getAccessTokenApi,
  fetchLocalCitiesData,
  parseDateWihtFormat,
  fetchTraveLiquidatorByIDData,
  fetchTravelByIDData,
  fetchSpecialLandTransport,
  parseDateLocaleFormat,
} from "@mfe/ts-common-ave-utilitaries";

import { useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import { BookGroundServicesComponent } from "../components/bookGroundService.component";

export default function BookGroundService({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <BookGroundServiceView organization={organization} />
    </MsalProvider>
  );
}

export const BookGroundServiceView = ({ organization }) => {
  const isAuthenticated = useIsAuthenticated();
  const { classes } = useStyles();
  const { instance, accounts } = useMsal();
  const [addedRoutes, setAddedRoutes] = useState([]);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [tokenApi, setTokenApi] = useState("");
  const [loading, setLoading] = useState(false);
  const [formRouteData, setFormRouteData] = useState([]);
  const [formAvailableData, setFormAvailableData] = useState([]);
  const [data, setData] = useState({
    cities: [],
    travelLiquidatorData: {
      ViajeTrayecto: [],
      TipoViajeAlias: "",
      TipoProceso: "",
      TipoViajeId: null,
      Multiviaje: false,
      Id: "",
    },
    travelStatus: {
      listaDestinos: [],
    },
  });

  const isMd = useWindowSize("md");

  const hourConverter = (hour) => {
    return moment(hour, "HH:mm").format("hh:mm A");
  };

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchLocalCitiesData(setData, tokenApi);
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoading
      );
      fetchTravelByIDData(
        tokenApi,
        localStorage.getItem("liquidationID"),
        setData,
        setLoading
      );
    }
  }, [tokenApi]);

  useEffect(() => {
    if (data?.travelStatus?.listaDestinos?.length) {
      const currentDate = new Date();
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");
      const hours = currentDate.getHours().toString().padStart(2, "0");
      const currentHour = `${hours}:${minutes}`;
      setFormRouteData(
        data?.travelStatus?.listaDestinos?.map((route) => {
          return {
            date: moment(route?.fechaIda).toDate(),
            hour: currentHour,
            origin: "",
            destination: "",
            observations: "",
          };
        })
      );
      setFormAvailableData(
        data?.travelStatus?.listaDestinos?.map((route) => {
          return {
            dates: [
              moment(route?.fechaIda).toDate(),
              moment(route?.fechaSalida).toDate(),
            ],
            initialHour: currentHour,
            finalHour: currentHour,
            destinations: "",
            observations: "",
          };
        })
      );

      setAddedRoutes(Array(data?.travelStatus?.listaDestinos?.length).fill([]));
      setAvailableRoutes(
        Array(data?.travelStatus?.listaDestinos?.length).fill([])
      );
    }
  }, [data?.travelStatus?.listaDestinos?.length]);

  const validationData =
    String(data?.travelLiquidatorData?.TipoProceso) === "1" ||
    data?.travelLiquidatorData?.TipoViajeAlias !== "TVINTERNCL";

  const allServicesBpo = lodash.flatMap(
    data?.travelLiquidatorData?.ViajeTrayecto,
    ({ ServicioBpo }) => ServicioBpo
  );

  const getHotelData = allServicesBpo.filter(
    ({ AliasServicio, Valor }) => AliasServicio === "HOTEL" && Valor > 0
  );

  const getFlightData = allServicesBpo.filter(
    ({ AliasServicio, Valor }) => AliasServicio === "AVION" && Valor > 0
  );

  const showService = lodash.memoize((alias) => {
    return lodash.some(
      allServicesBpo,
      (service) => service.Valor > 0 && service.AliasServicio === alias
    );
  });

  const routeData = (index) => [
    {
      type: "date",
      label: "Fecha",
      formValue: formRouteData?.[index]?.date,
      placeholder: "Seleccione Fecha",
      name: "date",
    },
    {
      type: "hour",
      label: "Hora",
      formValue: formRouteData?.[index]?.hour,
      placeholder: "Ingrese la hora",
      name: "hour",
    },
    {
      type: "text",
      label: "Origen",
      formValue: formRouteData?.[index]?.origin,
      placeholder: "Ingrese el origen",
      name: "origin",
    },
    {
      type: "text",
      label: "Destino",
      formValue: formRouteData?.[index]?.destination,
      placeholder: "Ingrese el destino",
      name: "destination",
    },
  ];

  const availableData = (index) => [
    {
      type: "select",
      label: "Destinos",
      formValue: formAvailableData?.[index]?.destinations,
      placeholder: "Ingrese los destinos",
      name: "destinations",
      data: data?.cities,
    },
    {
      type: "date",
      label: "Fecha(s)",
      formValue: formAvailableData?.[index]?.dates,
      placeholder: "Seleccione Fecha o Fechas",
      isRange: true,
      name: "dates",
    },
    {
      type: "hour",
      label: "Hora inicio",
      formValue: formAvailableData?.[index]?.initialHour,
      placeholder: "Ingrese la hora inicial",
      name: "initialHour",
    },
    {
      type: "hour",
      label: "Hora final",
      formValue: formAvailableData?.[index]?.finalHour,
      placeholder: "Ingrese la hora final",
      name: "finalHour",
    },
  ];

  const handleInputChange = (index, field, value, isAvailable = false) => {
    const updatedForm = [...formRouteData];
    const updatedAvailableForm = [...formAvailableData];
    let current = updatedForm[index];
    let currentAvailable = updatedAvailableForm[index];
    !isAvailable ? (current[field] = value) : (currentAvailable[field] = value);
    isAvailable
      ? setFormAvailableData(updatedAvailableForm)
      : setFormRouteData(updatedForm);
  };

  const handleSubmit = async () => {
    const payload = [...addedRoutes.flat(), ...availableRoutes.flat()].map(
      (item) => ({
        fkIdViaje: item?.idLiquidacion,
        idDestino: item?.idDestino,
        tipoServicio: item.type,
        ciudadOrigen: item.nombreCiudadOrigen,
        origen: item.origin || item.nombreCiudadDestino,
        destino: item.destination || item?.destinations?.split("/")?.[2],
        fechaInicio: item.date
          ? parseDateWihtFormat(item.date, "YYYY-MM-DD")
          : parseDateWihtFormat(item.dates[0], "YYYY-MM-DD"),
        fechaFin: item.date
          ? parseDateWihtFormat(item.date, "YYYY-MM-DD")
          : parseDateWihtFormat(item.dates[1], "YYYY-MM-DD"),
        hora: item.hour
          ? hourConverter(item.hour)
          : hourConverter(item.initialHour),
        horaFin: item.finalHour ? hourConverter(item.finalHour) : "",
        listaDestinos: item?.destinations?.split("/")?.[2] || "",
        observacion: item.observations,
        listaServicioBpo: allServicesBpo
          ?.filter((bpo) => bpo?.Valor > 0)
          ?.map(({ AliasServicio, descripcionServicio }) => {
            const parsedDescription = JSON.parse(descripcionServicio);
            const planeData =
              data?.travelLiquidatorData?.TipoViajeId === 2
                ? `${parsedDescription?.departureFlight?.flightSegments?.[0]?.departureAirport},${parsedDescription?.departureFlight?.flightSegments?.[0]?.departureCity}`
                : `${parsedDescription?.fligths?.[0]?.departureAirport},${parsedDescription?.fligths?.[0]?.departureCity}`;

            const planeHour =
              data?.travelLiquidatorData?.TipoViajeId === 2
                ? parsedDescription?.departureFlight?.flightSegments?.[0]
                    ?.arrivalDateTime
                : parsedDescription?.fligths?.[0]?.arrivalDateTime;
            return {
              lugar:
                AliasServicio === "AVION"
                  ? planeData
                  : AliasServicio === "HOTEL"
                  ? `${parsedDescription.nameHotel}, ${parsedDescription.destination}`
                  : "",
              fechaHora:
                AliasServicio === "AVION"
                  ? planeHour
                  : AliasServicio === "HOTEL"
                  ? parsedDescription.fechaInicio
                  : "",
              tipoServicioBpo: AliasServicio,
            };
          }),
      })
    );
    try {
      setLoading(true);
      await fetchSpecialLandTransport(tokenApi, payload);
      navigateToUrl("/reserva-datos-viajero");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const utils = {
    es,
    moment,
    parseDate,
    extractTime,
    parseDateWihtFormat,
    parseDateLocaleFormat,
  };
  const bookGroundServicesProps = {
    isMd,
    utils,
    data,
    StepperComponent,
    classes,
    validationData,
    getHotelData,
    getFlightData,
    showService,
    routeData,
    availableData,
    handleInputChange,
    handleSubmit,
    addedRoutes,
    setAddedRoutes,
    formRouteData,
    setFormRouteData,
    availableRoutes,
    setAvailableRoutes,
    formAvailableData,
    setFormAvailableData,
    GlobalCard,
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <BookGroundServicesComponent {...bookGroundServicesProps} />
  ) : null;
};
