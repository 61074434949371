import { useState } from "react";
import { Image, Flex } from "@mantine/core";
import { MdHideImage } from "react-icons/md";

export const ImageComponent = ({ defaultWidth = "unset", ...props }) => {
  const [errorImage, setErrorImage] = useState(true);

  const handleError = () => {
    setErrorImage(false);
  };
  return errorImage ? (
    <Image onError={handleError} {...props} />
  ) : (
    <Flex align="center" justify="center" h="100%" w={defaultWidth}>
      <MdHideImage size="2rem" color="#004236" />
    </Flex>
  );
};
