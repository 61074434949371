import { Flex, Text } from "@mantine/core";
import { FaInfo } from "react-icons/fa";

const InfoCard = ({ GlobalCard }) => {
  return (
    <GlobalCard
      styles={{
        margin: 0,
        color: "#004236",
        padding: "17px",
        borderRadius: "4px",
      }}
    >
      <Flex align="center" gap={4}>
        <FaInfo size="1rem" />
        <Text fz={14} fw={500}>
          Acompañantes CENIT:
        </Text>
      </Flex>
      <Text fz={14}>
        Nombre del funcionario y número de ID de viaje del acompañante o
        aprobación.
      </Text>
      <Flex align="center" gap={4} mt={8}>
        <FaInfo size="1rem" />
        <Text fz={14} fw={500}>
          Acompañantes externos:
        </Text>
      </Flex>
      <Text fz={14}>Nombre, No. De cédula, No. De contacto, Empresa</Text>
    </GlobalCard>
  );
};

export default InfoCard;
