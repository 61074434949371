import React, { useRef, useEffect } from "react";
import {
  Text,
  Grid,
  TextInput,
  Button,
  Textarea,
  ActionIcon,
  Select,
} from "@mantine/core";
import { FaCalendarDays, FaTrashCan } from "react-icons/fa6";
import { FaMapMarkerAlt, FaClock } from "react-icons/fa";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import InfoCard from "./inforCard.component";

export const GroundServiceCard = ({
  data,
  title,
  travelData,
  state,
  setState,
  handleInputChange,
  formData,
  setFormData,
  isAvailable,
  GlobalCard,
  classes,
  utils,
}) => {
  const timerRefs = useRef([]);

  const hourConverter = (hour) => {
    return utils.moment(hour, "HH:mm").format("hh:mm A");
  };

  const handleSubmit = (values, index, item) => {
    const newState = [...state];
    newState[index] = [
      ...newState[index],
      ...[
        {
          ...item,
          ...values,
          type: title.toLowerCase(),
        },
      ],
    ];
    setState(newState);
    const newFormData = [...formData];
    newFormData[index] = isAvailable
      ? {
          ...newFormData[index],
          destinations: "",
          observations: "",
        }
      : {
          ...newFormData[index],
          origin: "",
          destination: "",
          observations: "",
        };
    setFormData(newFormData);
  };

  const handleDelete = (index) => {
    const newState = [...state];
    newState[index].splice(index, 1);
    setState(newState);
  };

  const travelDates = travelData.flatMap((item) => [
    item?.fechaIda,
    item?.fechaSalida,
  ]);

  const excludeDate = (date) => {
    const dateToCheck = utils.moment(date);
    const [start, end] = travelDates?.map((date) => utils.moment(date));
    return !dateToCheck.isBetween(start, end, undefined, "[]");
  };

  const handleRefPicker = (ref) => {
    if (ref?.current?.showPicker) {
      ref.current.showPicker();
    }
  };

  useEffect(() => {
    timerRefs.current = data(0).map(() => React.createRef());
  }, []);

  return (
    <>
      <Text component="h2" fw={500} color="#004236" fz={22} ta="center">
        {title}
      </Text>
      {travelData?.map((item, indexTravel) => (
        <GlobalCard key={indexTravel}>
          <Text component="h3" color="#004236" fz="xl" fw={500} mt={0}>
            {`${item?.nombreCiudadOrigen} - ${item?.nombreCiudadDestino}`}
          </Text>
          <Grid align="flex-end">
            {data(indexTravel)?.map((item, index) => {
              const ref = timerRefs.current[index];

              if (item.type === "text") {
                return (
                  <Grid.Col xs={12} md={3} key={index}>
                    <TextInput
                      value={item.formValue}
                      onChange={(e) =>
                        handleInputChange(
                          indexTravel,
                          item.name,
                          e.currentTarget.value,
                          isAvailable
                        )
                      }
                      label={item.label}
                      icon={<FaMapMarkerAlt />}
                      classNames={{
                        input: classes.input,
                        label: classes.labelSecondary,
                      }}
                      placeholder={item.placeholder}
                    />
                  </Grid.Col>
                );
              } else if (item.type === "date") {
                return (
                  <Grid.Col xs={12} md={3} key={index}>
                    <DatePickerInput
                      value={item?.formValue}
                      onChange={(e) =>
                        handleInputChange(
                          indexTravel,
                          item.name,
                          e,
                          isAvailable
                        )
                      }
                      label={item.label}
                      locale={utils.es}
                      classNames={{
                        input: classes.input,
                        label: classes.labelSecondary,
                        day: classes.calendar,
                      }}
                      icon={<FaCalendarDays />}
                      valueFormat="DD-MMM-YY"
                      placeholder={item.placeholder}
                      type={item?.isRange ? "range" : "default"}
                      excludeDate={excludeDate}
                      allowSingleDateInRange
                    />
                  </Grid.Col>
                );
              } else if (item.type === "hour") {
                return (
                  <Grid.Col xs={12} md={3} key={index}>
                    <TimeInput
                      value={item.formValue}
                      onChange={(e) => {
                        handleInputChange(
                          indexTravel,
                          item.name,
                          e.target.value,
                          isAvailable
                        );
                      }}
                      label={item.label}
                      ref={ref}
                      rightSection={
                        <ActionIcon
                          data-testid="action-icon-ref"
                          onClick={() => handleRefPicker(ref)}
                        >
                          <FaClock size="1rem" />
                        </ActionIcon>
                      }
                      maw={400}
                      mx="auto"
                      classNames={{
                        input: classes.input,
                        label: classes.labelSecondary,
                      }}
                      style={{ maxWidth: "none" }}
                    />
                  </Grid.Col>
                );
              } else if (item.type === "select") {
                return (
                  <Grid.Col xs={12} md={3} key={index}>
                    <Select
                      value={item.formValue}
                      onChange={(e) =>
                        handleInputChange(
                          indexTravel,
                          item.name,
                          e,
                          isAvailable
                        )
                      }
                      placeholder="Ciudad Origen"
                      data={item.data}
                      nothingFound="No hay resultados"
                      searchable
                      classNames={{
                        dropdown: classes.dropdown,
                        rightSection: classes.rightSection,
                        label: classes.labelSecondary,
                        input: classes.input,
                        item: classes.item,
                      }}
                      icon={<FaMapMarkerAlt size="1rem" />}
                      label={item.label}
                    />
                  </Grid.Col>
                );
              }
            })}
            <Grid.Col xs={12} md={6}>
              <Textarea
                value={formData?.[indexTravel]?.observations}
                onChange={(e) =>
                  handleInputChange(
                    indexTravel,
                    "observations",
                    e.currentTarget.value,
                    isAvailable
                  )
                }
                label="Observaciones"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                minRows={5}
                placeholder="Ingrese las observaciones"
              />
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <InfoCard GlobalCard={GlobalCard} />
            </Grid.Col>
            <Grid.Col xs={12} md={3} offsetMd={9}>
              <Button
                fullWidth
                className={classes.button}
                data-testid="handleSubmit-button"
                onClick={() =>
                  handleSubmit(formData?.[indexTravel], indexTravel, item)
                }
                disabled={
                  formData?.[indexTravel]
                    ? !Object.values(formData?.[indexTravel])
                        ?.map((item) => String(item))
                        ?.every((value) => value.length > 0)
                    : true
                }
              >
                Añadir
              </Button>
            </Grid.Col>
          </Grid>
          {state?.[indexTravel]?.length > 0 && (
            <Grid bg="#fff" mt={16}>
              <Grid.Col span={12}>
                <Text fw={500} component="h4" size="lg" color="#004236" my={0}>
                  Trayecto(s)
                </Text>
              </Grid.Col>
              {state?.[indexTravel].map((item, index) => (
                <Grid.Col key={index} span={12}>
                  <Grid>
                    <Grid.Col offsetMd={11} md={1} xs={12} display="flex">
                      <ActionIcon
                        data-testid="action-icon"
                        onClick={() => handleDelete(index)}
                        ml="auto"
                      >
                        <FaTrashCan color="#004236" />
                      </ActionIcon>
                    </Grid.Col>
                    {item?.origin && (
                      <Grid.Col xs={12} md={6}>
                        <TextInput
                          label="Origen"
                          value={item.origin}
                          classNames={{
                            input: classes.input,
                            label: classes.labelSecondary,
                          }}
                          disabled={true}
                        />
                      </Grid.Col>
                    )}
                    {item?.destination && (
                      <Grid.Col xs={12} md={6}>
                        <TextInput
                          label="Destino"
                          value={item.destination}
                          classNames={{
                            input: classes.input,
                            label: classes.labelSecondary,
                          }}
                          disabled={true}
                        />
                      </Grid.Col>
                    )}
                    {item?.destinations && (
                      <Grid.Col xs={12} md={6}>
                        <TextInput
                          label="Destino(s)"
                          value={item.destinations.split("/")[2]}
                          classNames={{
                            input: classes.input,
                            label: classes.labelSecondary,
                          }}
                          disabled={true}
                        />
                      </Grid.Col>
                    )}
                    {item?.date && item?.hour && (
                      <Grid.Col xs={12} md={6}>
                        <TextInput
                          label="Fecha y hora"
                          value={`${utils.parseDate(item.date)} ${hourConverter(
                            item.hour
                          )}`}
                          classNames={{
                            input: classes.input,
                            label: classes.labelSecondary,
                          }}
                          disabled={true}
                        />
                      </Grid.Col>
                    )}
                    {item?.dates?.[0] && item?.initialHour && (
                      <Grid.Col xs={12} md={6}>
                        <TextInput
                          label="Fecha inicial"
                          value={`${utils.parseDate(
                            item.dates[0]
                          )} ${hourConverter(item.initialHour)}`}
                          classNames={{
                            input: classes.input,
                            label: classes.labelSecondary,
                          }}
                          disabled={true}
                        />
                      </Grid.Col>
                    )}
                    {item.dates?.[1] && item?.finalHour && (
                      <Grid.Col xs={12} md={6}>
                        <TextInput
                          label="Fecha final"
                          value={`${utils.parseDate(
                            item.dates[1]
                          )} ${hourConverter(item.finalHour)}`}
                          classNames={{
                            input: classes.input,
                            label: classes.labelSecondary,
                          }}
                          disabled={true}
                        />
                      </Grid.Col>
                    )}
                    {item.observations && (
                      <Grid.Col xs={12} md={6}>
                        <TextInput
                          label="Observaciones"
                          value={item.observations}
                          classNames={{
                            input: classes.input,
                            label: classes.labelSecondary,
                          }}
                          disabled={true}
                        />
                      </Grid.Col>
                    )}
                  </Grid>
                </Grid.Col>
              ))}
            </Grid>
          )}
        </GlobalCard>
      ))}
    </>
  );
};
