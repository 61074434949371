import { Box, Text, Button, Grid } from "@mantine/core";
import { ConfirmHotel } from "./confirmHotel.component";
import { ConfirmFlight } from "./confirmFlight.component";
import { GroundServiceCard } from "./groundServiceCard.component";

export const BookGroundServicesComponent = ({
  isMd,
  utils,
  data,
  StepperComponent,
  classes,
  validationData,
  getHotelData,
  getFlightData,
  showService,
  routeData,
  availableData,
  handleInputChange,
  handleSubmit,
  addedRoutes,
  setAddedRoutes,
  formRouteData,
  setFormRouteData,
  availableRoutes,
  setAvailableRoutes,
  formAvailableData,
  setFormAvailableData,
  GlobalCard,
}) => {
  const validateTravelData = data?.travelLiquidatorData?.Multiviaje
    ? data?.travelStatus?.listaDestinos
    : [data?.travelStatus?.listaDestinos?.[0]];

  const groundServiceCardTrayectProps = {
    data: routeData,
    title: "Trayecto",
    travelData: validateTravelData,
    state: addedRoutes,
    setState: setAddedRoutes,
    handleInputChange,
    formData: formRouteData,
    setFormData: setFormRouteData,
    isAvailable: false,
    GlobalCard,
    classes,
    utils,
  };

  const groundServiceCardAvailableProps = {
    data: availableData,
    title: "Disponible",
    travelData: validateTravelData,
    state: availableRoutes,
    setState: setAvailableRoutes,
    handleInputChange,
    formData: formAvailableData,
    setFormData: setFormAvailableData,
    isAvailable: true,
    GlobalCard,
    classes,
    utils,
  };

  return (
    <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
      <StepperComponent servicesData={data?.travelLiquidatorData} />
      <Text component="h1" color="#004236" fw={700} fz={24}>
        Servicio de transporte especial
      </Text>
      <Grid>
        <Grid.Col span={12}>
          {showService("HOTEL") && (
            <ConfirmHotel
              hotelData={getHotelData}
              GlobalCard={GlobalCard}
              utils={utils}
              isMd={isMd}
            />
          )}
        </Grid.Col>
        <Grid.Col span={12}>
          {showService("AVION") && (
            <ConfirmFlight
              isMd={isMd}
              utils={utils}
              data={getFlightData}
              validationData={validationData}
              GlobalCard={GlobalCard}
            />
          )}
        </Grid.Col>
      </Grid>

      <GroundServiceCard {...groundServiceCardTrayectProps} />

      <GroundServiceCard {...groundServiceCardAvailableProps} />

      <Button fullWidth className={classes.button} onClick={handleSubmit}>
        Continuar
      </Button>
    </Box>
  );
};
